<!--
 * @Author: yzr
 * @Date: 2020-11-24 09:58:27
 * @LastEditors: yzr
 * @LastEditTime: 2020-11-24 17:04:16
-->
<template>
  <div class="home">
    <TablePage />
  </div>
</template>

<script>
import TablePage from './table';

export default {
  name: 'Ads',
  components: {
    TablePage,
  },
};
</script>
